import React, { FunctionComponent, useState } from 'react'

import { Svg, SVGS } from '@components/redesign/Svgs'

import {
  availableTypes,
  mapValidPrefixTypes,
  typesClassNames
} from './InputFieldNew.constants'

import { IInputFieldNewProps } from './InputFieldNew.types'

import {
  style_component,
  style_input,
  style_prefix,
  style_postfix,
  style_disabled,
  style_invalid,
  style_checkboxLabel,
  style_toggleLabel,
  style_showPassword,
  style_spanPrefix,
  style_spanPostfix,
  style_pill
} from './InputFieldNew.module.css'
import { Red_5 } from '@theme/colors.module.css'

const mapToValidTypes = type => (type === 'toggle' ? 'checkbox' : type)

const InputFieldNew: FunctionComponent<IInputFieldNewProps> = ({
  validation,
  invalid,
  disabled,
  className = '',
  type = availableTypes[0],
  placeholder = '',
  pill = false,
  autoFocus = false,
  prefix,
  postfix,
  value,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const typeIndex = availableTypes.findIndex(t => t === type)
  const inputType = typeIndex ? type : availableTypes[0]
  const isText = inputType === 'text'
  const isCheckbox = inputType === 'checkbox'
  const isPassword = inputType === 'password'
  const isToggle = inputType === 'toggle'
  const hasPrefix = Boolean(prefix) && mapValidPrefixTypes.includes(type)
  const hasPostfix = Boolean(postfix) && mapValidPrefixTypes.includes(type)

  let validInputType = availableTypes.map(mapToValidTypes)[typeIndex]
  if (isPassword && showPassword) {
    validInputType = 'text'
  }

  const toggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <label
      className={[
        style_component,
        typesClassNames[inputType] ? typesClassNames[inputType] : '',
        invalid ? style_invalid : '',
        disabled ? style_disabled : '',
        pill ? style_pill : '',
        hasPrefix ? style_prefix : '',
        hasPostfix ? style_postfix : ''
      ].join(' ')}
    >
      {hasPrefix && <span className={style_spanPrefix}>{prefix}</span>}
      <input
        className={[style_input, className].join(' ')}
        type={validInputType}
        data-testid="InputFieldNew"
        disabled={disabled}
        placeholder={placeholder?.toString() || ' '}
        ref={validation}
        autoFocus={autoFocus}
        {...rest}
      />
      {hasPostfix && <span className={style_spanPostfix}>{postfix}</span>}
      {isCheckbox && <span className={style_checkboxLabel}>{placeholder}</span>}
      {isToggle && <span className={style_toggleLabel}>{placeholder}</span>}
      {(isPassword || (isText && showPassword)) && (
        <div className={style_showPassword} onClick={toggleShowPassword}>
          <Svg name={SVGS.Eye} color={invalid ? Red_5 : undefined} />
        </div>
      )}
    </label>
  )
}

export default InputFieldNew
