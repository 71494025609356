// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var Neutral_8 = "#0C0C0C";
export var Red_3 = "#E67878";
export var Red_5 = "#BC2323";
export var alpha_Neutral_8_10 = "rgba(12, 12, 12, 0.1)";
export var bleachedSilk = "#F2F2F2";
export var colors = "\"@theme/colors.module.css\"";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var style_component = "SelectInput-module--style_component--923ff typography-module--typeface_base--8d193";
export var style_invalid = "SelectInput-module--style_invalid--5a7a1";
export var style_selector = "SelectInput-module--style_selector--47467 typography-module--Body_Medium_XS--cadd2";
export var style_textElementLabel = "SelectInput-module--style_textElementLabel--7851e";
export var style_textElementWrapper = "SelectInput-module--style_textElementWrapper--4d439";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";