import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { shareCandidateProfile } from '@services/profile'

import type {
  MutationCallbackOptions,
  UseShareProfileReturn
} from './useShareProfile.types'
import type { ShareProfileArgs } from '@services/profile/profile.types'
import {
  ShareProfileStatus,
  ShareProfileSubmitErrors,
  type ShareProfileApiResponse
} from '@type/profile'

export const useShareProfile = (): UseShareProfileReturn => {
  const [shareProfileStatus, setShareProfileStatus] = useState(
    ShareProfileStatus.INITIAL
  )

  const shareProfileMutation = useMutation<
    ShareProfileApiResponse,
    AxiosError,
    ShareProfileArgs
  >({
    mutationFn: shareCandidateProfile,
    onSuccess: data => {
      if (data.error === ShareProfileSubmitErrors.ALREADY_SHARED) {
        setShareProfileStatus(ShareProfileStatus.ALREADY_SHARED)
        return
      }
      setShareProfileStatus(ShareProfileStatus.SUCCESSFUL)
    },
    onError: error => {
      setShareProfileStatus(ShareProfileStatus.FAILED)
      console.error(error)
    }
  })

  const shareProfile = (
    values: ShareProfileArgs,
    callbacks?: MutationCallbackOptions
  ) => shareProfileMutation.mutateAsync(values, callbacks)

  return {
    shareProfile,
    processing: shareProfileMutation.isLoading,
    shareProfileStatus
  }
}
