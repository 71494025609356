// extracted by mini-css-extract-plugin
export var Green_4 = "#44BB89";
export var Interface_SemiBold_L = "typography-module--Interface_SemiBold_L--52185";
export var Interface_SemiBold_M = "typography-module--Interface_SemiBold_M--61495";
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Neutral_8 = "#0C0C0C";
export var alpha_Neutral_8_10 = "rgba(12, 12, 12, 0.1)";
export var breakpointLG = "1280px";
export var breakpointMD = "1024px";
export var breakpointSM = "768px";
export var breakpointXL = "1440px";
export var breakpoints = "'@theme/breakpoints.module.css'";
export var colors = "'@theme/colors.module.css'";
export var style_button = "ShareProfileCtaBlock-module--style_button--21f14 typography-module--Interface_SemiBold_M--61495";
export var style_buttonBlock = "ShareProfileCtaBlock-module--style_buttonBlock--3213f";
export var style_buttonWhite = "ShareProfileCtaBlock-module--style_buttonWhite--d51dd ShareProfileCtaBlock-module--style_button--21f14 typography-module--Interface_SemiBold_M--61495";
export var style_card = "ShareProfileCtaBlock-module--style_card--6c310";
export var style_component = "ShareProfileCtaBlock-module--style_component--f826a typography-module--typeface_base--8d193";
export var style_initialCard = "ShareProfileCtaBlock-module--style_initialCard--9b4dc ShareProfileCtaBlock-module--style_card--6c310";
export var style_successfulCard = "ShareProfileCtaBlock-module--style_successfulCard--5a51a ShareProfileCtaBlock-module--style_card--6c310";
export var style_title = "ShareProfileCtaBlock-module--style_title--3428a typography-module--Interface_SemiBold_XL--876a5 typography-module--Interface_SemiBold_L--52185";
export var style_titleBlock = "ShareProfileCtaBlock-module--style_titleBlock--f662f";
export var style_titleWhite = "ShareProfileCtaBlock-module--style_titleWhite--a05d1 ShareProfileCtaBlock-module--style_title--3428a typography-module--Interface_SemiBold_XL--876a5";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";