// extracted by mini-css-extract-plugin
export var Body_Regular_M = "typography-module--Body_Regular_M--407d1";
export var Interface_Medium_M = "typography-module--Interface_Medium_M--2989f";
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_4 = "#8F8F8F";
export var Neutral_5 = "#707070";
export var Neutral_8 = "#0C0C0C";
export var Red_3 = "#E67878";
export var Red_5 = "#BC2323";
export var bleachedSilk = "#F2F2F2";
export var cerebralGrey = "#CCCCCC";
export var colors = "\"@theme/colors.module.css\"";
export var glimpseOfPink = "#fff3f5";
export var hornetSting = "#FF0035";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var style_checkbox = "InputFieldNew-module--style_checkbox--aa7d2";
export var style_checkboxLabel = "InputFieldNew-module--style_checkboxLabel--f6419 typography-module--Interface_Medium_M--2989f";
export var style_component = "InputFieldNew-module--style_component--2c639 typography-module--typeface_base--8d193 typography-module--Body_Regular_M--407d1";
export var style_disabled = "InputFieldNew-module--style_disabled--e41e7";
export var style_input = "InputFieldNew-module--style_input--ad156";
export var style_invalid = "InputFieldNew-module--style_invalid--dfca1";
export var style_password = "InputFieldNew-module--style_password--1037e";
export var style_passwordErrorMessage = "InputFieldNew-module--style_passwordErrorMessage--d2cff typography-module--Interface_SemiBold_S--af7a9";
export var style_pill = "InputFieldNew-module--style_pill--b872b";
export var style_postfix = "InputFieldNew-module--style_postfix--f7700";
export var style_prefix = "InputFieldNew-module--style_prefix--bc48c";
export var style_showPassword = "InputFieldNew-module--style_showPassword--3aa78";
export var style_spanPostfix = "InputFieldNew-module--style_spanPostfix--e633f";
export var style_spanPrefix = "InputFieldNew-module--style_spanPrefix--73181";
export var style_toggle = "InputFieldNew-module--style_toggle--5a559";
export var style_toggleLabel = "InputFieldNew-module--style_toggleLabel--52e72";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var weatheredStone = "#C4C4C4";
export var white = "#FFFFFF";