import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { fetchBhCountries } from '@services/jobApplication'

import type { UseCountriesReturn } from './useCountries.types'

export const useCountries = (): UseCountriesReturn => {
  const { data: countriesData, isLoading } = useQuery({
    queryKey: ['countries'],
    queryFn: fetchBhCountries
  })

  const data = useMemo(() => countriesData?.data?.data ?? [], [countriesData])

  return {
    data,
    isLoading
  }
}
