import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import SelectCreatable from 'react-select/creatable'

import { Svg, SVGS } from '@components/redesign/Svgs'

import { ISelectInputProps } from './SelectInput.types'

import {
  style_component,
  style_invalid,
  style_selector,
  style_textElementWrapper,
  style_textElementLabel
} from './SelectInput.module.css'

const SelectInput: FunctionComponent<ISelectInputProps> = ({
  invalid,
  isCreatable,
  options,
  async,
  multiple = false,
  isLoading = false,
  ...props
}) => {
  if (!options?.length) {
    return null
  }

  const getFormattedCreateLabel = (inputText: string) => (
    <div className={style_textElementWrapper}>
      <Svg name={SVGS.Plus} />
      <div className={style_textElementLabel}>{`Create "${inputText}"`}</div>
    </div>
  )

  const selectProps = {
    className: [style_selector, invalid ? style_invalid : ''].join(' '),
    classNamePrefix: 'react-select',
    options,
    isMulti: multiple,
    isLoading,
    ...props
  }

  return (
    <div data-testid="SelectInput" className={style_component}>
      {async ? (
        <AsyncSelect {...selectProps} />
      ) : isCreatable ? (
        <SelectCreatable
          formatCreateLabel={getFormattedCreateLabel}
          {...selectProps}
        />
      ) : (
        <Select {...selectProps} />
      )}
    </div>
  )
}

export default SelectInput
