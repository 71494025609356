import React from 'react'

import { Button } from '@components/redesign/Button'

import type { ShareProfileCtaBlockProps } from './ShareProfileCtaBlock.types'
import { ShareProfileStatus } from '@type/profile'

import {
  style_component,
  style_titleBlock,
  style_buttonBlock,
  style_initialCard,
  style_successfulCard,
  style_titleWhite,
  style_buttonWhite
} from './ShareProfileCtaBlock.module.css'

const SHARE_STATUS_TO_CARD_TITLE: Partial<
  Record<keyof typeof ShareProfileStatus, string>
> = {
  ALREADY_SHARED:
    'You successfully shared your profile. We’ll reach out when the right opportunity comes up!',
  SUCCESSFUL:
    'You successfully shared your profile. We’ll reach out when the right opportunity comes up!',
  INITIAL:
    'No matching job? Join us and we’ll reach out when the right opportunity comes up!'
}

const SHARE_STATUS_TO_CARD_CLASS_NAME: Partial<
  Record<keyof typeof ShareProfileStatus, string>
> = {
  ALREADY_SHARED: style_successfulCard,
  SUCCESSFUL: style_successfulCard,
  INITIAL: style_initialCard
}

export const ShareProfileCtaBlock = ({
  onShareClick,
  shareProfileStatus
}: ShareProfileCtaBlockProps) => (
  <div data-testid="ShareProfileCtaBlock" className={style_component}>
    <div className={SHARE_STATUS_TO_CARD_CLASS_NAME[shareProfileStatus]}>
      <div className={style_titleBlock}>
        <p className={style_titleWhite}>
          {SHARE_STATUS_TO_CARD_TITLE[shareProfileStatus]}
        </p>
      </div>
      {shareProfileStatus === ShareProfileStatus.INITIAL && (
        <div className={style_buttonBlock}>
          <Button className={style_buttonWhite} onClick={onShareClick}>
            Join our Talent Community
          </Button>
        </div>
      )}
    </div>
  </div>
)
