// extracted by mini-css-extract-plugin
export var Neutral_5 = "#707070";
export var Neutral_8 = "#0C0C0C";
export var cerebralGrey = "#CCCCCC";
export var colors = "\"@theme/colors.module.css\"";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var style_component = "ProfileForm-module--style_component--9f0b6 typography-module--typeface_base--8d193";
export var style_dropzone = "ProfileForm-module--style_dropzone--c0c61";
export var style_privacyPolicyText = "ProfileForm-module--style_privacyPolicyText--d8a24";
export var style_title = "ProfileForm-module--style_title--b16ba typography-module--fs18_fw700--08853";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";