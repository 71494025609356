/**
 * Converts a File object to Base64 string.
 * @param {File} file - The file to convert
 * @returns {Promise<string>} A promise that resolves with the Base64 content
 * @throws {Error} If a file is invalid or reading fails
 */
export const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event: ProgressEvent<FileReader>) => {
      try {
        const result = event.target?.result

        if (typeof result !== 'string') {
          return reject(new Error('FileReader did not produce a string result'))
        }

        // `result` contains the data URL
        // format: data:mimetype;base64,BASE64_DATA)
        // Remove the prefix part to get just the base64 content
        const base64String = result.split(',')[1]

        if (!base64String) {
          return reject(new Error('Failed to extract Base64 data from result'))
        }

        resolve(base64String)
      } catch (error) {
        reject(
          error instanceof Error
            ? error
            : new Error('Unknown error processing file')
        )
      }
    }

    reader.onerror = (event: ProgressEvent<FileReader>): void => {
      const error =
        event.target?.error ?? new Error('Unknown file reading error')
      reject(error)
    }

    // Read the file as a Data URL (Base64 encoded string)
    reader.readAsDataURL(file)
  })
