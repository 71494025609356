import type { EntityTypes } from '@bullhorn/bullhorn-types'
import { IFile } from '@type/file'
import { IJobType } from '@type/jobType'
import { ISkill } from '@type/skill'

export interface IProfile {
  id?: number
  email?: string
  fullName?: string
  about?: string
  githubAccount?: string
  hearAbout?: string
  image?: IFile
  jobAlertsNotification?: boolean
  linkedinAccount?: string
  minRate?: number
  preferredJobTypes?: IJobType[]
  preferredSkills?: ISkill[]
  rateRange?: string
  resume?: IFile
  stackoverflowAccount?: string
  subscribed?: boolean
  timezoneOffset?: number
  socialProfileImageUrl?: string
  updated_at?: Date | string
  created_at?: Date | string
  updatedAtByCandidate?: Date | string
  utmReferral?: string | null
  utmTerm?: string | null
  utmContent?: string | null
  utmCampaign?: string | null
  utmMedium?: string | null
  utmSource?: string | null
}

export type ProfileBody = {
  firstName: string
  lastName: string
  email: string
  country: {
    value: number
    label: string
  }
  hourlyRate: string
  resume: File[]
}

export enum ShareProfileStatus {
  INITIAL = 'INITIAL',
  SUCCESSFUL = 'SUCCESSFUL',
  ALREADY_SHARED = 'ALREADY_SHARED',
  FAILED = 'FAILED'
}

export enum ShareProfileSubmitErrors {
  ALREADY_SHARED = 'ALREADY_SHARED'
}

export type ShareProfileApiResponse = {
  data: {
    changeType: 'INSERT' | 'UPDATE'
    changedEntityId: number
    changedEntityType: EntityTypes
    data: any
  } | null
  error: string | null
}
