import {
  fetchBhCandidateByEmail,
  parseResume,
  updateBhCandidateWithResumeData
} from '../jobApplication'

import { makeBhApiCall } from '@utils/bullhornHelpers'
import { fileToBase64 } from '@utils/fileHelpers'

import type { ShareProfileArgs } from './profile.types'
import type { CreateCandidateBody } from '../jobApplication/jobApplication.types'
import { ShareProfileSubmitErrors } from '@type/profile'

export const uploadResumeForCandidate = async (
  candidateId: number,
  resume: File
) => {
  const base64EncodedFileData = await fileToBase64(resume)

  return makeBhApiCall({
    method: 'uploadCandidateCV',
    body: {
      fileUploadData: {
        externalID: `cv-candidate-#-${candidateId}`,
        fileContent: base64EncodedFileData,
        fileType: 'SAMPLE',
        name: resume.name,
        contentType: resume.type
      },
      entityId: candidateId
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const shareCandidateProfile = async ({
  firstName,
  lastName,
  email,
  country,
  hourlyRate,
  resume
}: ShareProfileArgs) => {
  try {
    // Check if the candidate already exists in Bullhorn
    const candidate = await fetchBhCandidateByEmail(email)
    const isExistingCandidate = candidate.data.count > 0
    const resumeFile = resume[0]

    if (isExistingCandidate) {
      const [existingCandidate] = candidate.data.data ?? []
      // We still want to upload a CV in case it's updated
      // or if another version is uploaded
      await uploadResumeForCandidate(existingCandidate.id, resumeFile)

      return {
        data: null,
        error: ShareProfileSubmitErrors.ALREADY_SHARED
      }
    }

    const parsedResume = await parseResume(resumeFile)

    const { address, companyName, description, mobile, occupation } =
      parsedResume?.data?.data?.candidate ?? {}
    const { candidateEducation, candidateWorkHistory, primarySkills } =
      parsedResume?.data?.data ?? {}

    // Create Candidate
    const createdCandidate = await makeBhApiCall<CreateCandidateBody>({
      method: 'createCandidate',
      body: {
        candidateData: {
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          email,
          address: {
            ...address,
            countryID: country.value,
            countryName: country.label
          },
          hourlyRate: Number.parseInt(hourlyRate),
          companyName,
          description,
          mobile,
          occupation
        }
      }
    })

    const candidateId = createdCandidate.data.changedEntityId

    // Update the candidate with related entities
    await updateBhCandidateWithResumeData(candidateId, {
      candidateEducation,
      candidateWorkHistory,
      primarySkills
    })

    // Attach CV file to the candidate page
    await uploadResumeForCandidate(candidateId, resumeFile)

    return createdCandidate
  } catch (error) {
    console.error(error)
    throw error
  }
}
