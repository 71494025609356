import React, { Suspense } from 'react'

import { ProfileForm } from '@components/redesign/ProfileForm'
import { ShareProfileCtaBlock } from '@components/redesign/ShareProfileCtaBlock'

import { useCountries } from '@hooks/useCountries'
import { useModal } from '@hooks/useModal'
import { useShareProfile } from '@hooks/useShareProfile'

const Modal = React.lazy(() => import('@components/redesign/Modal/Modal'))

export const ShareProfile = () => {
  const { shareProfile, shareProfileStatus } = useShareProfile()

  const { isVisible: isModalVisible, toggleModal } = useModal()
  const { data: countries, isLoading: isLoadingCountries } = useCountries()

  const handleSubmitProfile = async data => {
    await shareProfile(data)
    toggleModal()
  }

  const renderModal = () =>
    isModalVisible && (
      <Suspense fallback={<div />}>
        <Modal onClose={toggleModal}>
          <ProfileForm
            title="Share your profile"
            countries={countries}
            isLoadingCountries={isLoadingCountries}
            handleConfirmation={data => handleSubmitProfile(data)}
            submitErrorMessage="Error submitting profile:"
            buttonLabel="Submit profile"
            buttonSubmittingLabel="Submitting profile..."
            button-data-value="submit-profile-button"
          />
        </Modal>
      </Suspense>
    )

  return (
    <>
      {renderModal()}
      <ShareProfileCtaBlock
        onShareClick={toggleModal}
        shareProfileStatus={shareProfileStatus}
      />
    </>
  )
}
