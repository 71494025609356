import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm, Controller } from 'react-hook-form'

import { FormLayoutContainer } from '@containers/FormLayoutContainer'
import { ButtonNew } from '@components/redesign/ButtonNew'
import { InputFieldNew } from '@components/redesign/InputFieldNew'
import { InputSection } from '@components/redesign/InputSection'
import { SelectInput } from '@components/redesign/SelectInput'

import { emailValidationPattern, size } from '@utils/helpers'

import type { FormInputs, ProfileFormProps } from './ProfileForm.types'

import {
  style_component,
  style_title,
  style_dropzone,
  style_privacyPolicyText
} from './ProfileForm.module.css'

export const ProfileForm = ({
  handleConfirmation,
  countries,
  isLoadingCountries,
  title,
  submitErrorMessage = 'Error submitting form',
  buttonLabel = 'Submit',
  buttonSubmittingLabel = 'Submitting form...',
  'button-data-value': buttonDataValue = ''
}: ProfileFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    setValue
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('resume', acceptedFiles, { shouldValidate: true })
    },
    [setValue]
  )

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: ['.pdf'],
    maxFiles: 1,
    maxSize: 4000000, // 4Mb
    onDrop
  })

  const hasFormErrors = size(formErrors) > 0
  const canEdit = !isSubmitting
  const canSubmit = !hasFormErrors && canEdit && acceptedFiles.length > 0

  const handleOnSubmit = async (data: FormInputs) => {
    setIsSubmitting(true)
    try {
      await handleConfirmation(data)
    } catch (error) {
      console.error(submitErrorMessage, error)
    }
    setIsSubmitting(false)
  }

  return (
    <div className={style_component}>
      <h1 className={style_title}>{title}</h1>
      <FormLayoutContainer onSubmit={handleSubmit(handleOnSubmit)}>
        <InputSection
          label="First name"
          invalid={formErrors?.firstName?.message}
          compact
        >
          <InputFieldNew
            name="firstName"
            validation={register({
              required: 'First name is required.',
              validate: value =>
                Boolean(value.trim()) || 'First name cannot be empty'
            })}
            placeholder="First name"
            disabled={!canEdit}
            invalid={Boolean(formErrors.firstName)}
          />
        </InputSection>
        <InputSection
          label="Last name"
          invalid={formErrors?.lastName?.message}
          compact
        >
          <InputFieldNew
            name="lastName"
            validation={register({
              required: 'Last name is required.',
              validate: value =>
                Boolean(value.trim()) || 'Last name cannot be empty'
            })}
            placeholder="Last name"
            disabled={!canEdit}
            invalid={Boolean(formErrors.lastName)}
          />
        </InputSection>
        <InputSection
          label="Email address"
          invalid={formErrors?.email?.message}
          compact
        >
          <InputFieldNew
            name="email"
            validation={register({
              required: 'Email address is required.',
              pattern: emailValidationPattern
            })}
            placeholder="Email address"
            disabled={!canEdit}
            invalid={Boolean(formErrors.email)}
          />
        </InputSection>
        <Controller
          name="country"
          control={control}
          rules={{
            required: 'Country is required.'
          }}
          render={({ onChange, value, name }) => (
            <InputSection
              label="Country"
              invalid={formErrors?.country?.message}
              compact
            >
              <SelectInput
                onChange={onChange}
                value={value}
                placeholder="Country"
                isDisabled={!canEdit}
                isLoading={isLoadingCountries}
                name={name}
                searchable
                invalid={Boolean(formErrors.country?.message)}
                options={
                  isLoadingCountries
                    ? [{ label: 'Loading countries...', value: 0 }]
                    : countries
                }
              />
            </InputSection>
          )}
        />
        <InputSection
          label="Desired pay rate"
          invalid={formErrors?.hourlyRate?.message}
          compact
        >
          <InputFieldNew
            name="hourlyRate"
            type="number"
            postfix="USD per hour"
            validation={register({
              required: 'Desired pay rate is required.',
              validate: value =>
                Number(value) >= 0 || 'Desired pay rate must be 0 or greater'
            })}
            min={0}
            placeholder="Desired pay rate"
            disabled={!canEdit}
            invalid={Boolean(formErrors.hourlyRate)}
          />
        </InputSection>
        <Controller
          name="resume"
          control={control}
          rules={{
            required: 'Resume is required.',
            validate: {
              fileSize: files =>
                !files?.[0] ||
                files[0].size <= 4000000 ||
                'File size must be less than 4MB',
              fileType: files =>
                !files?.[0] ||
                files[0].type === 'application/pdf' ||
                'Only PDF files are allowed'
            }
          }}
          render={() => (
            <InputSection
              label="Resume"
              invalid={Boolean(formErrors.resume)}
              description="Please upload your resume in PDF format"
              compact
            >
              <div
                {...getRootProps({
                  className: `dropzone ${style_dropzone}`
                })}
              >
                <input {...getInputProps()} />
                {acceptedFiles.length > 0 ? (
                  <div>
                    <p>Selected file: {acceptedFiles[0].name}</p>
                    <p className="text-sm text-gray-500">
                      Click or drag to replace
                    </p>
                  </div>
                ) : (
                  <p>Click to upload or drag and drop your PDF resume here</p>
                )}
              </div>
            </InputSection>
          )}
        />
        <p className={style_privacyPolicyText}>
          For details on how X-Team handle personal information, please visit
          our{' '}
          <a
            href="https://x-team.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <ButtonNew
          data-value={buttonDataValue}
          block
          disabled={!canSubmit}
          type="submit"
          uppercase={false}
          size="large"
          style={{ marginTop: '16px' }}
        >
          {isSubmitting ? buttonSubmittingLabel : buttonLabel}
        </ButtonNew>
      </FormLayoutContainer>
    </div>
  )
}
